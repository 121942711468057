
  import AeqPhoneField from '~/components/AeqPhoneField'

  import VueReCaptcha from '~/plugins/vue-recaptcha-v3'

  export default {
    name: `RegisterPage`,
    options: {
      auth: `guest`
    },
    components: {
      AeqPhoneField
    },
    data() {
      return {
        steps: {
          companyNumber: 1,
          userNumber: 2,
          legalNumber: 3
        },
        step: 1,
        blurPhoneField: false,
        inputPhone: ``,
        phoneData: {},
        form: {
          company: {
            name: ``,
            size: ``,
            street_1: ``,
            street_2: ``,
            zip: ``,
            locality: ``,
            state: ``,
            country: `CH`,
            website: ``
          },
          user: {
            title: ``,
            first_name: ``,
            last_name: ``,
            job_title: ``,
            email: ``,
            phone: ``,
            language: this.$i18n.locale
          },
          legal: {
            agreement: false
          },
          plan: ``
        },
        dialogConfirmTerms: false,
        onboarding_done: false,
      }
    },
    async fetch() {
      await this.$store.dispatch(`formData/onboardingGetOptions`)
    },
    computed: {
      langLocales() {
        return this.$helpers.languagesLocales()
      },
      countries() {
        return this.$store.state.formData.onboarding.countries
      },
      states() {
        const tempsState = this.$store.state.formData.onboarding.states || []
        return tempsState.length > 0 ? tempsState.filter((state) => state.country === this.form.company.country) : []
      },
      sizes() {
        return this.$store.state.formData.onboarding.company_sizes
      },
      titles() {
        return [`F`, `M`].map((g) => ({
          text: this.$t(`components.genders.${g.toLocaleLowerCase()}`),
          value: g
        }))
      },
      stob() {
        return this.$store.state.formData.onboarding.stob
      },
    },
    watch: {
      '$i18n.locale'(value) {
        this.form.user.language = value
        this.$store.dispatch(`formData/onboardingGetOptions`)
      },
      'form.user.last_name'(newValue, oldValue) {
        newValue !== oldValue && (this.form.user.last_name = newValue.toUpperCase())
      }
    },
    mounted() {
      const recaptcha = document.querySelector(`.grecaptcha-badge`)
      if (!!recaptcha) {
        recaptcha.style.display = `block`
      } else {
        VueReCaptcha(this.$config.public.recaptcha_key)
      }

      if (this.$route.query.plan) {
        this.form.plan = this.$route.query.plan
      }
    },
    destroyed() {
      const recaptcha = document.querySelector(`.grecaptcha-badge`)
      if (!!recaptcha) {
        recaptcha.style.display = `none`
      }
    },
    methods: {
      goToStep(idStep) {
        if (idStep > this.step) {
          for (let n = this.step; n < idStep; n++) {
            switch (n) {
              case this.steps.companyNumber:
                this.validCompany()
                break
              case this.steps.userNumber:
                this.validUser()
                break
            }
          }
        } else {
          this.step = idStep
        }
      },
      async validCompany() {
        const companyIsValid = await this.$refs.companyObs.validate()
        if (companyIsValid) {
          this.step = this.steps.userNumber
        }
      },
      async validUser() {
        const userIsValid = await this.$refs.userObs.validate()
        if (userIsValid) {
          this.step = this.steps.legalNumber
        }
      },
      async send() {
        const checkTerms = await this.$refs.termsObs.validate()
        if (checkTerms) {
          // Recaptcha
          await this.$recaptchaLoaded()
          const token = await this.$recaptcha(`register`)
          // POST Register
          this.$api.onboarding
            .send({
              form: this.form,
              captcha_token: token
            })
            .then(() => {
              this.onboarding_done = true
            })
            .catch((err) => {
              if (err?.response?.status === 400) {
                // Form errors
                let pageErr = this.steps.legalNumber
                if (!!err?.data?.form?.legal) {
                  this.$refs.termsObs.setErrors(err.data.form.legal)
                }
                if (!!err?.data?.form?.user) {
                  this.$refs.userObs.setErrors(err.data.form.user)
                  pageErr = this.steps.userNumber
                }
                if (!!err?.data?.form?.company) {
                  this.$refs.companyObs.setErrors(err.data.form.company)
                  pageErr = this.steps.companyNumber
                }

                // Captcha Error
                if (!!err?.data?.captchaToken) {
                  this.$flashMessage.error({
                    title: this.$t(`messages.error`),
                    message: this.$t(err.data.captchaToken[0])
                  })
                }
                this.step = pageErr
              }
            })
        }
      }
    }
  }
